import { getAllNodeByFilter } from "common/adapters/entities"
import { CustomOption, customStylesWithPadding } from "common/components/selectComponents"
import { setNodalNode, setNodeOffset, setNodeInputValue } from "pages/home/stores/slices/settings-slice"
import classes from 'common/components/divContainer/style.module.scss';
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AsyncPaginate, wrapMenuList  } from "react-select-async-paginate"
import { components } from 'react-select';
import { ApplicationState } from "store"
import toast from "react-hot-toast";


interface Props {
    isLoadingEnabled: boolean
}

// MenuList must be outside of Component - scrolling gets reset to top if inside Node. 
const CustomMenuList = (props: any) => {
    return (
      <>
        <div className={classes.labelContainer3ColsHeader__name}>
            <div className={classes.labelContainer3ColsHeader__left}>Name</div>
            <div className={classes.labelContainer3ColsHeader__middle}>Bus</div>
            <div className={classes.labelContainer3ColsHeader__right}>Neartest Substation</div>
        </div>
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
      </>
    );
  };
const WrappedMenuList: any = wrapMenuList(CustomMenuList);

/**
 * Node Component
 * 
 * A React component for selecting and displaying nodes in Aypa Nodal.
 * 
 * @param {Props} props - Component properties
 * @param {boolean} props.isLoadingEnabled - Flag indicating whether loading is enabled
 * 
 * @returns {JSX.Element} - React JSX Element representing the Node component
 */

export const Node = ({ isLoadingEnabled }: Props) => {

    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const locationId = useSelector((store: ApplicationState) => store.settingsReducer.locationId);
    const regionId = useSelector((store: ApplicationState) => store.settingsReducer.regionId);
    const forecastId = useSelector((store: ApplicationState) => store.settingsReducer.forecastId);
    const nodeInputValue = useSelector((store: ApplicationState) => store.settingsReducer.nodeInputValue);
    const nodeOffset = useSelector((store: ApplicationState) => store.settingsReducer.nodeOffset);
    const nodeId = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);

    const dispatch = useDispatch()

    const [nodeSelected, setNodeSelected] = useState<object>()

    /**
     * Handler for onChange event when a node is selected.
     * 
     * @param {any} selectedOption - Selected option representing the node with node ID and name
     * 
     * @returns {void}
     */

    const handlerOnChangeNode = (selectedOption: any) => {
        if (locationId == null) {
            dispatch(setNodeOffset(0));
        }
        setNodeSelected(selectedOption);
        dispatch(setNodalNode({ nodeId: selectedOption.value,
            nodeName: selectedOption.label,
            locationId: selectedOption.locationId,
            locationName: selectedOption.locationName
        }));
    }

    let options = []

    /**
     * Async function to load node options based on user input.
     * This function is triggered during/when,
     * 1) initialization of the component
     * 2) user input is given in the node selector
     * 3) location changes
     * 
     * @param {string} inputValue - User input for filtering nodes
     * 
     * @returns {Promise<{ options: Array<any>, hasMore: boolean }>} - Promise with loaded options and hasMore flag
     */

    const loadOptions = async (inputValue: string) => {
        let currentOffset = nodeOffset;
        if (nodeInputValue !== inputValue) {
            currentOffset = 0
            dispatch(setNodeOffset(0));
            dispatch(setNodeInputValue(inputValue))
        }
        try {
            const response = await getAllNodeByFilter(sourceId, inputValue, regionId, forecastId, locationId, currentOffset);
            options = response.data.items.map((s: any) => {
                const label = (
                    <div className={classes.labelContainer3Cols__name}>
                        <div className={classes.labelContainer3Cols__left}>{s.name}</div>
                        <div className={classes.labelContainer3Cols__middle}>{s.bus_id}</div>
                        <div className={classes.labelContainer3Cols__right}>{s.substation_name ? s.substation_name : ''}</div>
                    </div>

                );
                return { value: s.id, label: s.name, locationName: s.location_name, locationId: s.location_id, longLabel: label };
            });
            dispatch(setNodeOffset(currentOffset + 50)); // Revisit and fix the error toast issue !!!
            if (options.length === 1) {
                handlerOnChangeNode(options[0])
            }
            return {
                options: options,
                hasMore: response.data.total >= nodeOffset
            };
        } catch (error) {
            toast.error("Failed to load the Aypa Nodal nodes list. Please try again.");
            return { options: [], hasMore: false };
        }

    };

    useEffect(() => {
        if (nodeId == null) {
            setNodeSelected([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeId])

    return (
        <>
            <AsyncPaginate
                id="nodalNodeDropdown"
                key={JSON.stringify({ locationId, forecastId })}
                defaultOptions
                value={nodeSelected}
                onChange={handlerOnChangeNode}
                loadOptions={loadOptions}
                className="react-select-container-nodal"
                classNamePrefix="react-select"
                components={{ MenuList: WrappedMenuList, Option: CustomOption }}
                menuPlacement='auto'
                styles={customStylesWithPadding}
                isDisabled={isLoadingEnabled || !forecastId}
            />
        </>
    )
}