import { Dropdown } from "react-bootstrap";
import classes from "./../../style.module.scss";
import { ButtonDownloadPriceProfileCsv } from "./buttonDownloadPriceProfileCsv";
import { ButtonDownloadPriceProfileAsPng } from "./buttonDownloadPriceProfileAsPng";
import { ButtonDownloadSimulationCsv } from "./buttonDownloadSimulationCsv";
import { ButtonDownloadSimulationAsPng } from "./buttonDownloadSimulationAsPng";

interface Props {
    priceProfile: boolean,
    simulate: boolean,
    simulateTable: boolean,
    checkAypaToken: Function

}

export const ButtonDownloadMenu = ({priceProfile, simulate, simulateTable, checkAypaToken}: Props) => {
    checkAypaToken();
    return (
       
            <Dropdown className={classes.card__dropdown}>
                <Dropdown.Toggle id="dropdown-downloads">
                    Download
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {priceProfile && <Dropdown.Item as={ButtonDownloadPriceProfileCsv}></Dropdown.Item>}
                    {priceProfile &&<Dropdown.Item as={ButtonDownloadPriceProfileAsPng}></Dropdown.Item>}
                    {(simulate || simulateTable) &&<Dropdown.Item as={ButtonDownloadSimulationCsv}></Dropdown.Item>}
                    {simulate &&<Dropdown.Item as={ButtonDownloadSimulationAsPng}></Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
    )
}
