import { createSlice } from '@reduxjs/toolkit'
import { PriceState } from '../states/price-state'

const initialState: PriceState = {
    isLoadingData: false,
    isLoadingChart: false,
    errorMessage: null,
    isCSVDownloading: false,
    isDone: false,
    executeRunPriceLookup: false,
    executeRunPriceLookupRaw: false,
    loadedData: "",
    completedPriceLookup: false,
    completedDownloadCSV: false,
    loadedDataList: {},
    completedList: {},
    onOffPeak: false,
    peakStart: 6,
    peakEnd: 21
}

export const priceSlice = createSlice({
    name: 'price',
    initialState,
    reducers: {
        priceInitialised(state) {
            // On Price button click. on settings form changes
            Object.assign(state, initialState);
        },
        fetchRunPriceLookup(state) {
            // After price button is clicked - triggers websocket or rest call
            state.isLoadingData = true;
            state.isLoadingChart = true;
            state.executeRunPriceLookup = true;
        },
        fetchRunPriceLookupRaw(state) {
            // After Raw Tab is clicked - triggers websocket or rest call
            // no isLoadingData - thats loading gif for over all tabs
            state.isLoadingChart = true;
            state.executeRunPriceLookupRaw = true;
            state.completedList = {};
            state.loadedDataList = {};
        },
        successRunPriceLookup(state, action) {
            // on success message returned that is not COMPLETED - append data
            if (action.payload.event_id in state.loadedDataList) {
                state.loadedDataList[action.payload.event_id] += action.payload.data;
            } else {
                state.loadedDataList[action.payload.event_id] = action.payload.data;
            }
        },
        requestCompleted(state, action) {
            // once COMPLETE is returned from WebSocket for mean-prices-stream.
            state.isLoadingData = false;
            state.completedList[action.payload] = true;
        },
        requestCompletedRaw(state, action) {
            // once COMPLETE is returned from WebSocket for raw-prices-stream.
            state.completedList[action.payload] = true;
        },
        successRunForecastLookup(state, action) {
            // after rest data recieved
            state.isLoadingData = false;
            state.completedList[action.payload.event_id] = true;
            state.loadedDataList[action.payload.event_id] = action.payload.data;
        },
        successRunForecastRawLookup(state, action) {
            // after rest data recieved for raw data
            // state.isLoadingData = false;
            state.completedList[action.payload.event_id] = true;
            state.loadedDataList[action.payload.event_id] = action.payload.data;
        },
        setCSVDownloading(state, action) {
            // after download button pressed - starts websocket
            state.isCSVDownloading = action.payload;
            state.loadedData = "";
            state.completedDownloadCSV = false
        },
        successDownloadPriceLookup(state, action) {
            // websocket download on success message returned that is not COMPLETED - append data
            state.loadedData += action.payload;
        },
        downloadCompleted(state) {
            // websocket download COMPLETE is returned
            state.completedDownloadCSV = true;
        },
        successOperationPrice(state) {
            // after data is loaded and converted to table, reset data.
            // after download completed
            // turn off isLoadingChart once the data is converted to table
            state.isLoadingChart = false;
            state.isDone = true;
            state.isCSVDownloading = false;
            state.completedDownloadCSV = false;
            state.completedPriceLookup = false;
            state.completedList = {};
            state.loadedDataList = {};
            state.executeRunPriceLookup = false;
            state.executeRunPriceLookupRaw = false;
        },
        requestFailed(state, action) {
            // websocket or rest failed
            state.isLoadingData = false;
            state.isLoadingChart = false;
            state.errorMessage = action.payload;
            state.completedList = {};
            state.loadedDataList = {};
            state.executeRunPriceLookup = false;
            state.executeRunPriceLookupRaw = false;
            state.completedDownloadCSV = false;
            state.isCSVDownloading = false;
        },
        toggleOnOffPeakRunPriceLookup(state) {
            // After price button is clicked - triggers price lookup again
            state.isLoadingData = true;
            state.isLoadingChart = true;
            state.executeRunPriceLookup = true;
            state.onOffPeak = !state.onOffPeak
        },
        setPeakStart(state, action) {
            state.peakStart = action.payload;
        },
        setPeakEnd(state, action) {
            state.peakEnd = action.payload;
        }
    },
});

export const { priceInitialised,
    fetchRunPriceLookup, fetchRunPriceLookupRaw, successRunPriceLookup,
    requestCompleted, requestCompletedRaw,
    successRunForecastLookup, successRunForecastRawLookup,
    setCSVDownloading,
    successDownloadPriceLookup,
    downloadCompleted,
    successOperationPrice,
    requestFailed, toggleOnOffPeakRunPriceLookup, setPeakStart, setPeakEnd } = priceSlice.actions;

export default priceSlice.reducer;